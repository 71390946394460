<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container-fluid mt-4">

        <b-alert :show="loading" variant="info">Loading...</b-alert>
        <b-row>
            <b-col>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="site in sites" :key="site.id">
                            <td>{{ site.name }} <b-badge v-if="site.status == 'Archived'" pill variant="warning">Archived</b-badge></td>
                            <td>
                                    <b-button variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="$router.push('/customer-account/' + $parent.customerId + '/site/' + site.id + '/statistics')">
                                        <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
                                    </b-button>
                                    <b-button v-if="isUserAuthorized"
                                              variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="populateSiteToEdit(site)">
                                        <b-icon-pencil />
                                    </b-button>
                                    <b-button v-if="isIntentoSales"
                                              variant="outline-primary"
                                              size="sm"
                                              class="m-1"
                                              @click.prevent="populateAccountToMove(site)">
                                        <b-icon-arrow-left-right />
                                    </b-button>
                                    <b-button v-if="isUserAuthorized"
                                              variant="outline-primary"
                                              size="sm"
                                              class="my-1"
                                              @click.prevent="deleteSite(site.id)">
                                        <b-icon-trash />
                                    </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>

            <b-modal id="modal-add-site"
                     @ok="saveSite"
                     ok-title="Save Site"
                     :title="(model.id ? 'Edit Site': 'New Site')">
                <form @submit.prevent="saveSite">
                    <b-form-group label="Site Name">
                        <b-form-input type="text" v-model="model.name"></b-form-input>
                    </b-form-group>
                </form>
                <div class="form-group">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
            </b-modal>

            <b-modal id="modal-move-site"
                     @ok="moveSite"
                     ok-title="Move"
                     title="Change Customer Account"
                     :ok-disabled=!Boolean(modelSiteToMoveSelected)>
                <form @submit.prevent="moveSite">
                    <b-form-group label="Destination customer account">
                        <b-form-select v-model="modelSiteToMoveSelected" :options="modelSiteToMoveOptions"></b-form-select>
                    </b-form-group>
                </form>
                <div class="form-group">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
            </b-modal>

        </b-row>

        <b-row>
            <b-col>
                <b-button v-if="isUserAuthorized" size="sm" class="m-1" variant="primary" 
                @click.prevent="showNewSiteForm()">
                    <b-icon-plus font-scale="1.3"></b-icon-plus>Add Site
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import ApiService from '@/services/api.service';

    export default {
        data() {
            return {
                loading: false,
                sites: [],
                model: {},
                modelSiteToMove : null,
                modelSiteToMoveSelected: null,
                modelSiteToMoveOptions: [ ],
                errorMessage: ''
            };
        },
        async created() {
            this.refreshSites();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales', 'isCustomerUser', 'getCustomerIdOfAccountManager' ])
        },
        methods: {
            isUserAuthorized() {
                return this.isIntentoSales || 
                    (this.isCustomerUser && this.customerIdOfAccountManager == this.$parent.customerId)
            },
            async refreshSites() {
                this.loading = true;
                this.sites = await ApiService.getCustomerSites(this.$parent.customerId);
                this.loading = false;
            },
            async showNewSiteForm() {
                this.model = {};
                this.$bvModal.show('modal-add-site');
                this.errorMessage = '';
            },
            async populateSiteToEdit(site) {
                this.model = Object.assign({}, site);
                this.$bvModal.show('modal-add-site');
                this.errorMessage = '';
            },
            async populateAccountToMove(site) {

                this.model = Object.assign({}, site);
                this.modelSiteToMove = site.id;
                this.modelSiteToMoveOptions = [];
                this.errorMessage = '';

                var customerAccounts = await ApiService.getCustomerAccounts();
                for (var customer of customerAccounts) {
                    if (site.customerAccountId != customer.id) {
                        this.modelSiteToMoveOptions.push({ value: customer.id, text: customer.company});
                    }
                }

                this.$bvModal.show('modal-move-site');
            },
            async saveSite(event) {
                event.preventDefault();
                if (this.model.id) {
                    await ApiService.updateSite(this.model.id, this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-add-site");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                } else {
                    this.model.status = "Active"
                    this.model.customerAccountId = Number(this.$parent.customerId)
                    await ApiService.addSite(this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-add-site");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                }
                await this.refreshSites();
            },
            async moveSite(event) {
                event.preventDefault();
                this.model.customerAccountId = this.modelSiteToMoveSelected;
                await ApiService.updateSite(this.model.id, this.model).then(
                            () => { 
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-move-site");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                this.refreshSites();
            },
            async deleteSite(id, name) {
                if (confirm(`Are you sure you want to delete site ${name} ?`)) {
                    if (this.model.Id === id) {
                        this.model = {};
                    }
                    await ApiService.deleteSite(id);
                    await this.refreshSites();
                }
            }
        }
    };
</script>
